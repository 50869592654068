import authApi, { setApiAuthorizationHeader } from "../authinstance";
import keys from "../keys";
// const user_id = localStorage.getItem("beauty_id")
const queryString = require('query-string');
class Auth {
    async radius(params) {
        try {
            const response = await authApi.get(`${keys.SERVER_URL}/utils/general/`);
            return response.data;
        } catch (error) {
            console.log(error);
        }
    }
    async article(data) {
        try {
            const response = await authApi.get(`${keys.SERVER_URL}utils/article/?limit=1000&offset=0&lang=${data.language}&active=True&category=${data?.category ? data?.category : ''}&web_category=${data?.web_category ? data?.web_category : ''}&web_top=${data?.web_top ? data?.web_top : ''}`);
            return response.data;
        } catch (error) {
            console.log(error);
        }
    }
    async help() {
        try {
            const response = await authApi.get(`${keys.SERVER_URL}utils/help/`);
            return response.data;
        } catch (error) {
            console.log(error);
        }
    }
    async posts(params) {
        let query = queryString.stringify({ ...params.data, radius: params.radius });
        try {
            const response = await authApi.get(`${keys.SERVER_URL}timeline/post/post-by-radius/?${params ? query : ""}`);
            return response.data;
        } catch (error) {
            console.log(error);
        }
    }
    async popular_master(params) {
        const { token, ...data } = params;
        let query = queryString.stringify({ ...data });
        try {
            const response = await authApi.get(`${keys.SERVER_URL}userdetails/master/?ordering=popularity&${query}`);
            return response.data;
        } catch (error) {
            console.log(error);
        }
    }
    async comment_by_post_id({ token, ...params }) {
        let query = queryString.stringify(params);
        try {
            setApiAuthorizationHeader(token);
            const response = await authApi.get(`${keys.SERVER_URL}/timeline/post-comment/?${params ? query : ""}`);
            return response.data;
        } catch (error) {
            console.log(error);
        }
    }
    async like_or_dislike(data) {
        try {
            if (data.token) {    
                setApiAuthorizationHeader(data.token);
            }
            const response = await authApi.get(`${keys.SERVER_URL}/timeline/like-or-dislike/${data.id}/`);
            return response.data;
        } catch (error) {
            console.log(error);
        }
    }
    async add_new_comment({ token, ...data }) {
        try {
            setApiAuthorizationHeader(token);
            const response = await authApi.post(`${keys.SERVER_URL}/timeline/post-comment/`, data);
            return response.data;
        } catch (error) {
            console.log(error);
        }
    }
    async post_data(data) {
        try {
            setApiAuthorizationHeader(data.token);
            const response = await authApi.get(`${keys.SERVER_URL}/timeline/post/${data.id}/`);
            return response.data;
        } catch (error) {
            console.log(error);
        }
    }
    async add_view_post({ token, id }) {
        try {
            setApiAuthorizationHeader(token);
            const response = await authApi.post(`${keys.SERVER_URL}timeline/post/${id}/add-view/`);
            return response.data;
        } catch (error) {
            console.log(error);
        }
    }
    async hide_post(data) {
        try {
            setApiAuthorizationHeader(data.token);
            const response = await authApi.get(`${keys.SERVER_URL}/timeline/hide-post/${data.id}/`);
            return response.data;
        } catch (error) {
            console.log(error);
        }
    }
    async report_post(data) {
        const { token, ...rest } = data;
        try {
            setApiAuthorizationHeader(token);
            const response = await authApi.post(`${keys.SERVER_URL}/timeline/post-report/`, rest);
            return response.data;
        } catch (error) {
            console.log(error);
        }
    }

}
const category = new Auth();
export default category;
